
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TipoPensao } from '@sgprev-enums';
import { Pensao } from '@sgprev-entities';

@Injectable()
export class AppService {
    private tipoPensaoObservable = new Subject<any>();
    public tipoPensao!: TipoPensao;
    public tipoPensaoObservable$ = this.tipoPensaoObservable.asObservable();

    private dadosPensaoObservable = new Subject<any>();
    public dadosPensao!: Pensao;
    public dadosPensaoObservable$ = this.dadosPensaoObservable.asObservable();


    constructor() {
    }

    updatePensao(pensao: TipoPensao) {
        this.tipoPensao = pensao;
        this.tipoPensaoObservable.next(this.tipoPensao);
    }

    updateDadosPensao(dadosPensao: Pensao) { 
        this.dadosPensao = dadosPensao;
        this.dadosPensaoObservable.next(this.dadosPensao);
    }

}
